const createRect = (regl, props) => {
  return regl(Object.assign({
    attributes: { position: [-2, 0, 0, -2, 2, 2] },
    depth: { enable: false },
    count: 3,
    viewport: { x: 0, y: 0, width: regl.prop('width'), height: regl.prop('height') }
  }, props))
}

export default createRect
