import background from 'modules/background/background'
import PageManager from 'navigation/SuperPageManager'
import scroll from 'core/scroll'
import resize from 'helpers/resize'
import SuperPage from 'navigation/SuperPage'
import Cursor from 'modules/cursor/Cursor'

import Project from './project/Project'
import Projects from './projects/Projects'
import Home from './home/Home'
import About from './about/About'

class Main {
  constructor (el) {
    this.el = el
    this.createVirtualScroll()

    this.listenResize()

    scroll.init()

    this.cursor = new Cursor(document.querySelector('.cursor'))

    background.init().then(() => {
      this.createPageManager()
    })
  }

  createPageManager () {
    this.pageManager = new PageManager(
      document.querySelector('.container'),
      '.page',
      [
        { route: '/projects/*', pageClass: Project },
        { route: '/projects/?', pageClass: Projects },
        { route: '/about', pageClass: About },
        { route: '', pageClass: Home },
        { route: '*', pageClass: SuperPage }
      ], {
        mapModule: {
          // '.reveal-block': Reveal,
          // '.reveal-text': TextReveal,
          // '.img-scroller': ImageScroller
        }
      })
    this.resize()
  }

  createVirtualScroll () {
    scroll.init()
  }

  listenResize () {
    resize.add(this)
    this.resize()
  }

  resize = () => {
    this.pageManager && this.pageManager.resize()
  };
}

export default Main
