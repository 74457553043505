import Photos from 'sections/photos/Photos'
import Video from 'sections/video/Video'

const Project =  function (el, options) {
  console.log()
  const isVideo = !!~el.className.indexOf('video')
  if (isVideo) return new Video(el, options)
  return new Photos(el, options)
}

Project.pageName = "Project"


export default Project
