
import raf from '@internet/raf'
import { find } from 'lodash'
import Inrtia from 'inrtia'
import Lottie from 'lottie-web'
import anime from 'animejs'

import config from 'core/config'
import SuperPage from 'navigation/SuperPage'
import text from 'helpers/text'
import router from 'core/router'
import detect from 'helpers/detect'

let lastVideoTime = 0
const timeline = JSON.parse(config.home.timecode)

const load = (url) => new Promise(resolve => {
  const xhr = new XMLHttpRequest()
  xhr.responseType = 'json'
  xhr.onload = () => {
    resolve(xhr.response)
  }
  xhr.open('GET', url, true)
  xhr.send()
})

const homeLogo = load(config.home.logos)

export default class Home extends SuperPage { 
 static pageName = 'Home'; 
  constructor (el, options = {}) {
    super(...arguments)

    this.inrtia = new Inrtia({
      value: { x: 0, y: 0 },
      precision: 0.1,
      perfectStop: true,
      friction: 6
    })
  }

  preload (previousPage) {
    return super.preload(previousPage, {
      muted: true,
      currentTime: previousPage === 'Video' || previousPage === 'Photos' ? lastVideoTime : 0,
      intro: detect.desktop && !previousPage
    })
      .then((player) => {
        this.videoPlayer = player
        this.toggleEvents()
      })
      .then(() => homeLogo)
      .then((animationData) => this.createLottie(animationData))
  }

  createLottie (animationData) {
    this.lottie = Lottie.loadAnimation({
      container: this.refs.logos,
      renderer: 'canvas',
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: {
        dpr: Math.min(detect.desktop ? 1 : 2, window.devicePixelRatio)
      }
    })
  }

  toggleEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    raf[add ? 'add' : 'remove'](this.raf)
    document.body[method]('mousemove', this.mousemove)
    if (detect.touch) {
      this.refs.link[method]('touchstart', this.touchstart)
      this.refs.link[method]('touchend', this.touchend)
    } else {
      this.refs.link[method]('click', this.click)
    }
  }

  touchstart = (event) => {
    event.preventDefault()
    this.refs.link.classList.add('hold')
    this.holding = setTimeout(() => this.click(), 1000)
  }

  touchend = (event) => {
    this.refs.link.classList.remove('hold')
    clearTimeout(this.holding)
  }

  mousemove = (event) => {
    this.inrtia.to({ x: event.clientX, y: event.clientY })
    this.refs.cursor.classList.toggle('visible', event.target === this.refs.link)
  }

  click = (event) => {
    event && event.preventDefault()
    if (this.project) router.navigate(this.project.project)
  }

  raf = () => {
    this.timeUpdate()
    const v = this.inrtia.update()
    if (this.inrtia.stopped) return
    this.refs.cursor.style.transform = `translate(${v.x}px, ${v.y}px)`
  }

  timeUpdate = () => {
    if (!this.refs.cursorTitle) return
    const { currentTime } = this.videoPlayer

    if (!Math.round(currentTime)) return

    const project = find(timeline, t => currentTime >= t.from && currentTime < t.to)

    if (!project || !this.project || project.id !== this.project.id) {
      this.project = project
      this.refs.cursorTitle.innerText = project ? project.title : ''
      this.refs.link.setAttribute('href', project ? project.project : '')
    }

    const h = Math.floor(currentTime / 3600)
    const m = Math.floor(currentTime % 3600 / 60)
    const s = Math.floor(currentTime % 3600 % 60)
    const ms = Math.floor((currentTime - Math.floor(currentTime)) * 24)

    this.refs.cursorTimecode.innerHTML = `TC ${text.spanify(text.pad(h))}:${text.spanify(text.pad(m))}:${text.spanify(text.pad(s))}:${text.spanify(text.pad(ms))}`
  }


  hide (nextPage) {
    lastVideoTime = nextPage === 'Project' ? this.videoPlayer.currentTime : 0

    console.log(lastVideoTime)
    return Promise.all([
      super.hide(),
      anime({
        targets: this.refs.cursorCross,
        scale: 0,
        easing: 'easeOutQuad',
        duration: 400
      }).finished
    ])
  }

  flush () {
    this.lottie && this.lottie.destroy()
    this.toggleEvents(false)
    super.flush()
  }

  resize () {
    super.resize()
  }
}
