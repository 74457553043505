
import SuperPage from 'navigation/SuperPage'

export default class ProjectIndex extends SuperPage { 
 static pageName = 'ProjectIndex'; 
  constructor (el, options = {}) {
    super(...arguments)
    this.toggleEvents()
  }

  toggleEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.refs.links.forEach(e => e[method]('mouseenter', this.mouseenter))
    this.refs.links.forEach(e => e[method]('mouseleave', this.mouseleave))
  }

  getVideoBlur () {
    return 1
  }

  getVideoOpacity () {
    return .55
  }

  mouseenter = event => {
    const id = event.currentTarget.getAttribute('data-id')
    this.refs.images.forEach(image => {
      image.classList.toggle('visible', id === image.getAttribute('data-id'))
    })
  }

  mouseleave = event => {
    this.refs.images.forEach(image => {
      image.classList.toggle('visible', false)
    })
  }

  flush () {
    this.toggleEvents(false)
    super.flush()
  }

  resize () {
    super.resize()
  }
}
