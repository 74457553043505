
import hotmaterial from '@internet/hotmaterial'
import createREGL from 'regl'
import anime from 'animejs'
import Lottie from 'lottie-web'
import { throttle } from 'lodash'

import math from 'helpers/math'
import resize from 'helpers/resize'
import detect from 'helpers/detect'
import browser from 'helpers/browser'
import promise from 'helpers/promise'
import config from 'core/config'

import createRect from './core/createRect'
import color from 'helpers/color'

const blur = hotmaterial(
  require('@internet/hmr!./shaders/default.vert'), // eslint-disable-line
  require('@internet/hmr!./shaders/blur.frag') // eslint-disable-line
)
const base = hotmaterial(
  require('@internet/hmr!./shaders/default.vert'), // eslint-disable-line
  require('@internet/hmr!./shaders/video.frag') // eslint-disable-line
)
const offset = hotmaterial(
  require('@internet/hmr!./shaders/default.vert'), // eslint-disable-line
  require('@internet/hmr!./shaders/offset.frag') // eslint-disable-line
)

const load = (url) => new Promise(resolve => {
  const xhr = new XMLHttpRequest()
  xhr.responseType = 'json'
  xhr.onload = () => {
    resolve(xhr.response)
  }
  xhr.open('GET', url, true)
  xhr.send()
})

const transition = load(config.home.transition)
const duration = 1500
export default class Scene {
  constructor (canvas, wrapper) {
    this.first = true
    this.canvas = canvas
    this.tick = 0
    this.blurAmount = 0
    this.opacityAmount = 1
    this.aspectRatio = 'cover'
    this.renderLowFps = throttle(this._render, 1000 / 20)

    this.lottieWrapper = wrapper
    this.lottiePromise = transition.then((data) => this.initLottie(data, wrapper))
  }

  init () {
    this.regl = createREGL({
      canvas: this.canvas
      // extensions: detect.mobile ? ['OES_texture_half_float'] : ['OES_texture_float', 'oes_texture_float_linear']
    })
    this.prepareCommands()
    this.toggleEvents()
    this.resize()
  }

  initLottie (animationData, container) {
    return Lottie.loadAnimation({
      container,
      renderer: 'canvas',
      loop: true,
      autoplay: false,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
        // preserveAspectRatio: 'xMidYMid meet'
      }
    })
  }

  clearVideo () {
    if (!this.video) return
    this.video.src = ''
    this.video.pause()
  }

  setupPage ({ opacity, blur }) {
    this.setBlur(blur)
    this.setOpacity(opacity)
  }

  playVideo (src, { intro, muted = false, aspectRatio = 'cover', currentTime = 0 } = {}) {
    if (this.video && (src === this.video.src || !this.video.src))
      return Promise.resolve(this.video)

    if (!src || src.type === 'color') {
      if (!this.first) this.animateLottie()
      return this.preanimate()
        .then(() => {
          this.empty = true
          this.clearVideo()
          this.base.video(src.value && {
            width: 1,
            height: 1,
            data: [
              ...color.hexToRGB(src.value), 255
            ]
          })
          return this.video
        })
    }

    this.empty = false

    const p = promise.defer()
    const video = document.createElement('video')
    video.className = 'page__video'
    video.src = src
    video.currentTime = currentTime
    video.loop = true
    video.muted = muted
    video.playsInline = true

    video.oncanplay = () => {
      if (!detect.safari) video.pause()
      video.oncanplay = null
      p.resolve()
    }

    video.play().catch(() => {
      video.muted = true
      video.play()
    })

    const switchVideo = () => {
      if (this.video) this.clearVideo()
      this.video = video
      this.aspectRatio = aspectRatio

      this.base.video(video)
      this.resize()
      video.play()
      this.first = false
      return video
    }

    return p.promise
      .then(() => {
        this.animating = true
        if (intro) {
          this.fadeIn(switchVideo)
        } else {
          if (!this.first) return this.animate(switchVideo)
          switchVideo()
          return this.postanimate()
        }
      })
      .then(() => {
        this.animating = false
      })
      .then(() => video)
  }

  fadeIn (callback) {
    this.opacityAmount = 0
    return anime({
      targets: this,
      opacityAmount: 1,
      duration: 800,
      delay: 3500,
      changeBegin: callback,
      easing: 'easeOutSine'
    }).finished
  }

  preanimate () {
    const timeline = anime.timeline({
      targets: this.offset,
      duration: 1,
      easing: 'easeInSine'
    })

    timeline.add({
      progress: [0.5, .4],
      duration: duration / 2
    })

    timeline.add({
      begin: () => {
        this.offset.zoom = 1.8
      }
    }, duration * .66)

    return timeline.finished
  }

  postanimate () {
    const timeline = anime.timeline({
      targets: this.offset,
      duration: 1,
      easing: 'easeOutSine'
    })

    timeline.add({
      progress: [.4, .3],
      duration: duration / 2
    })

    timeline.add({
      begin: () => {
        this.offset.zoom = 1
      }
    }, duration * .33)

    return timeline.finished.then(() => {
      this.offset.progress = 0
      this.offset.zoom = 1
    })
  }

  setBlur (blurAmount) {
    if (this.blurAmount === blurAmount) return Promise.resolve()

    return anime({
      targets: this,
      blurAmount: blurAmount,
      duration: 800,
      easing: 'easeOutSine',
      begin: () => {
        if (blurAmount) this.resize()
      }
    }).finished.then(() => {
      if (!blurAmount) this.resize()
    })
  }

  setOpacity (opacityAmount) {
    if (this.opacityAmount === opacityAmount) return Promise.resolve()
    return anime({
      targets: this,
      opacityAmount: opacityAmount,
      duration: 800,
      easing: 'easeOutSine'
    }).finished
  }

  animate (callback) {
    this.animateLottie()
    return this.preanimate()
      .then(callback)
      .then(() => this.postanimate())
  }

  animateLottie () {
    const obj = { progress: 0 }
    this.lottieWrapper.style.visibility = 'visible'
    return anime({
      targets: obj,
      progress: 1,
      duration: duration,
      easing: 'linear',
      update: () => {
        this.lottiePromise.then((l) => {
          l.goToAndStop((obj.progress * l.getDuration(true)) >> 0, true)
        })
      }
    }).finished.then(() => {
      this.lottieWrapper.style.visibility = 'hidden'
    })
  }

  // animate () {
  //   anime({
  //     targets: this,
  //     animeProgress: [0, 1],
  //     easing: 'easeInOutQuad',
  //     duration: 2000,
  //     delay: 3000
  //   }).finished
  //     .then(() => {
  //       this.switchColor()
  //       this.animate()
  //     })
  // }

  prepareCommands () {
    const filter = detect.desktop ? 'nearest' : 'nearest'
    // const type = detect.desktop ? 'float' : 'half float'
    const textureProps = { mag: filter, min: filter, mipmap: false, format: 'rgb', aniso: 0 }
    const framebufferProps = { depth: false, stencil: false }

    this.base = {
      framebuffer: this.regl.framebuffer(framebufferProps),
      video: this.regl.texture(textureProps)
    }

    this.blur = {
      framebuffer: this.regl.framebuffer(framebufferProps),
      buffers: [
        this.regl.texture(Object.assign({ data: '' }, textureProps)),
        this.regl.texture(Object.assign({ data: '' }, textureProps))
      ]
    }

    this.offset = {
      progress: 0,
      zoom: 1
    }

    this.base.draw = this.videoCommand(this.regl, this.base.framebuffer)
    this.blur.draw = this.blurCommand(this.regl, this.blur.framebuffer)
    this.offset.draw = this.offsetCommand(this.regl, this.offset.framebuffer)

    // const image = new Image()
    // image.src = '/images/logo.png'
    // image.onload = () => {
    //   this.color.logo({ data: image, mag: filter, min: 'nearest mipmap nearest', wrap: 'clamp', mipmap: 'nice', width: 512, height: 512 })
    // }
  }

  mousemove= (event) => {
    event.preventDefault()
    event = browser.mouseEvent(event)
    this.inrtia.to([event.clientX / resize.width(), event.clientY / resize.height()])
  }

 videoCommand = (regl, framebuffer) => createRect(regl, {
   frag: base.frag,
   vert: base.vert,
   uniforms: {
     uSource: regl.prop('source'),
     uRatio: regl.prop('ratio'),
     uTime: regl.prop('time'),
     uRes: regl.prop('res'),
     uVideoMatrix: regl.prop('videoMatrix')
   },
   framebuffer
 })

 blurCommand = (regl, framebuffer) => createRect(regl, {
   frag: blur.frag,
   vert: blur.vert,
   uniforms: {
     uSource: regl.prop('source'),
     uDelta: regl.prop('delta'),
     uRatio: regl.prop('ratio'),
     uTime: regl.prop('time'),
     uRes: regl.prop('res')
   },
   framebuffer
 })

  offsetCommand = (regl, framebuffer) => createRect(regl, {
    frag: offset.frag,
    vert: offset.vert,
    uniforms: {
      uZoom: regl.prop('zoom'),
      uOpacity: regl.prop('opacity'),
      uOffset: regl.prop('offset'),
      uSource: regl.prop('source'),
      uRatio: regl.prop('ratio'),
      uTime: regl.prop('time'),
      uRes: regl.prop('res')
    }
    // framebuffer
  })

  devicePixelRatio = () => Math.min(detect.desktop ? 1.5 : 2, window.devicePixelRatio)

  render = () => {
    if (this.blurAmount) this.renderLowFps()
    else this._render()
  }

  _render = () => {
    this.tick++

    const { width, height } = this.size
    const { width: vWidth, height: vHeight } = this.viewPortsize
    const base = {
      res: [vWidth, vHeight],
      ratio: width / height,
      time: this.tick,
      width: width,
      height: height
    }

    if (this.video && !this.video.paused && !this.empty) this.base.video.subimage(this.video)

    this.base.draw({ ...base, source: this.base.video, videoMatrix: this.videoMatrix })

    if (this.blurAmount) {
      const iterations = 6
      let delta, ping, pong

      for (let i = 0; i < iterations; i++) {
        const radius = (iterations - i - 1) * this.blurAmount
        ping = i % 2
        pong = ping ? 0 : 1
        delta = ping === 0 ? [radius, 0] : [0, radius]

        this.blur.framebuffer({ color: this.blur.buffers[pong] })
        this.blur.draw({ ...base, source: i === 0 ? this.base.framebuffer : this.blur.buffers[ping], delta })
      }
    }

    this.offset.draw({
      ...base,
      source: this.blurAmount ? this.blur.framebuffer : this.base.framebuffer,
      offset: this.offset.progress,
      zoom: this.offset.zoom,
      opacity: this.opacityAmount
    })
  }

  resize = () => {
    const multiplier = this.blurAmount ? 0.2 : 1
    this.size = {
      width: resize.width() * this.devicePixelRatio() * multiplier,
      height: resize.height() * this.devicePixelRatio() * multiplier
    }

    this.viewPortsize = {
      width: resize.width(),
      height: resize.height()
    }

    this.videoMatrix = this.video ? this.getVideoMatrix(this.video) : [1, 0, 0, 0, 1, 0, 0, 0, 1]

    this.canvas.width = this.size.width
    this.canvas.height = this.size.height

    const elements = [this.base, this.blur]
    elements.forEach(element => {
      element.framebuffer && element.framebuffer.resize(this.size.width, this.size.height)
      element.buffers && element.buffers.forEach(b => b.resize(this.size.width, this.size.height))
    })

    this.lottiePromise.then((l) => l.resize())
  }

  getVideoMatrix (video) {
    const { videoWidth, videoHeight } = this.video
    const { width, height } = this.viewPortsize

    const _scale = math.scale(this.aspectRatio, width, height, videoWidth, videoHeight)
    const scale = [
      1 / ((videoWidth * _scale) / width),
      1 / ((videoHeight * _scale) / height)
    ]

    const mat = [
      scale[0], 0, 0.5 - (scale[0] / 2),
      0, scale[1], 0.5 - (scale[1] / 2),
      0, 0, 1
    ]

    return mat
  }

  toggleEvents (add = true) {
    const m1 = add ? 'add' : 'remove'
    // const m2 = add ? 'addEventListener' : 'removeEventListener'
    resize[m1](this)
    if (add) this.raf = this.regl.frame(this.render)
    else this.raf.cancel()
  }

  flush () {
    this.toggleEvents(false)
  }
}
