
import resize from 'helpers/resize'
import Player from 'modules/player/Player'

import AbstractProject from '../abstract-project/AbstractProject'

export default class Video extends AbstractProject { 
 static pageName = 'Video'; 
  constructor (el, options = {}) {
    super(el, {
      moduleMap: {
        player: ['.video', Player]
      }
    })
  }

  getVideoUrl () {
    return this.refs.video.src
  }

  preload (previousPage) {
    return super.preload(previousPage, {
      aspectRatio: 'contain'
      // aspectRatio: resize.mq.tabletPortrait() ? 'contain' : 'cover'
    }).then((player) => {
      this.videoPlayer = player
    })
  }

  onImageLoad () {
    super.onImageLoad()
    this.modules.player.init(this.videoPlayer)
  }

  toggleEvents (add = true) {
    super.toggleEvents(...arguments)
  }

  resize () {
    super.resize()
  }
}
