import Emitter from 'tiny-emitter'

import Scene from 'canvas/Scene'

const emitter = new Emitter()
let scene, loadingPromise

const init = () => {
  const canvas = document.querySelector('.background__canvas')
  const wrapper = document.querySelector('.background__lottie')
  scene = new Scene(canvas, wrapper)

  loadingPromise = Promise.resolve()
    .then(() => scene.init())
    .then(() => emitter.emit('ready'))

  // scene.on('update', canvasUpdate)

  return loadingPromise
}

const setupPage = (options) => {
  return scene.setupPage(options)
}

const playVideo = (...options) => {
  return scene.playVideo(...options)
}

// const canvasUpdate = (e) => {
//   emitter.emit('update', e)
// }

const background = Object.assign(emitter, {
  init,
  playVideo,
  setupPage
})

export default background
