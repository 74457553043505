
import anime from 'animejs'

import Carousel from 'modules/library/carousel/Carousel'

import AbstractProject from '../abstract-project/AbstractProject'

export default class Photos extends AbstractProject { 
 static pageName = 'Photos'; 
  constructor (el, options = {}) {
    super(...arguments)

  }

  init () {
    this.carousel = new Carousel(this.refs.wrapper, this.refs.images)
    this.carousel.prevent = true
    setTimeout(() => this.toggleEvents(), 0)
  }

  getVideoUrl () {
    return {
      type: 'color',
      value: this.refs.container.getAttribute('data-background')
    }
  }

  show (previousPage) {
    this.showGallery(previousPage)
    // setTimeout(() => {
    //   this.el.style.opacity = 1
    // }, 50)
    // return super.show(...arguments, this.el.querySelectorAll('.photos__arrow, .project__header'))
    return super.show(...arguments)

  }

  hide () {
    this.hideGallery()
    return super.hide(...arguments)
  }

  showGallery (previousPage) {
    return anime({
      targets: this.refs.wrapper,
      translateY: ['100%', 0],
      easing: 'easeOutSine',
      delay: !previousPage ? 0 : 400,
      duration: 500
    }).finished
  }

  hideGallery () {
    return anime({
      targets: this.refs.wrapper,
      translateY: [0, '-100%'],
      easing: 'easeInSine',
      duration: 500
    }).finished
  }

  toggleEvents (add = true) {
    super.toggleEvents(...arguments)
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.refs.prev[method]('click', this.prev)
    this.refs.next[method]('click', this.next)

    this.carousel[add ? 'on' : 'off']('update', this.onCarouselUpdate)
  }

  prev = () => {
    this.carousel.prev()
  }

  next = () => {
    this.carousel.next()
  }

  onCarouselUpdate = (step, total) => {
    this.refs.prev.classList.toggle('disable', step <= 0)
    this.refs.next.classList.toggle('disable', step >= total - 1)
  }

  resize () {
    this.carousel && this.carousel.resize()
    super.resize()
  }

  flush () {
    this.carousel.flush()
    super.flush()
  }
}
