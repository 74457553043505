
import raf from '@internet/raf'

import scroll from 'core/scroll'
import SuperPage from 'navigation/SuperPage'

export default class About extends SuperPage { 
 static pageName = 'About'; 
  constructor (el, options = {}) {
    super(...arguments)
    this.progress = 0
    this.toggleEvents()
    this.imagesToLoad = this.imagesToLoad.concat(this.refs.logos)
  }

  onImageLoad () {
    super.onImageLoad(...arguments)
    this.buildImage()
  }

  buildImage () {
    const height = 180 * 1
    this.canvas = document.createElement('canvas')
    const context = this.canvas.getContext('2d')
    const scales = this.refs.logos.map((l) => height / l.naturalHeight)
    const width = this.refs.logos.reduce((memo, tag, i) => {
      return memo + (tag.naturalWidth * scales[i])
    }, 0)

    this.canvas.width = width
    this.canvas.height = height

    let offset = 0

    this.refs.logos.forEach((tag, i) => {
      const width = tag.naturalWidth * scales[i]
      context.drawImage(tag, offset, 0, width, height)
      offset += width
    })

    this.lineRatio = width / height

    this.refs.lines.forEach((tag, i) => {
      // tag.style.setProperty('--ratio', ratio)
      tag.style.backgroundImage = 'url(\'' + this.canvas.toDataURL() + '\')'
    })
  }

  toggleEvents (add = true) {
    // const method = add ? 'addEventListener' : 'removeEventListener'
    raf[add ? 'add' : 'remove'](this.raf)
    scroll.instance()[add ? 'on' : 'off'](this.scroll)
  }

  scroll = (event) => {
    this.deltaY = event.deltaY
  }

  raf = () => {
    this.progress += 1 / (60 * 10)

    if (this.deltaY) {
      this.progress += Math.abs(this.deltaY / 2000)
      this.deltaY = false
    }

    if (this.progress > 1) this.progress -= 1
    const width = this.lineHeight * this.lineRatio

    this.refs.lines[0].style.backgroundPositionX = `${width * this.progress}px`
    this.refs.lines[1].style.backgroundPositionX = `${width * (1 - this.progress)}px`
  }

  getVideoBlur () {
    return 1
  }

  getVideoOpacity () {
    return .3
  }

  flush () {
    this.toggleEvents(false)
    super.flush()
  }

  resize () {
    this.lineHeight = this.refs.lines[0].offsetHeight
    super.resize()
  }
}
