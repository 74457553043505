
var moduleId = require.resolve("./default.vert");

var watchers = [];
var i = 0;
var l = 0;

var api = {
  __hmr: true,
  id: moduleId,
  module: require("./default.vert"),
  watch: watch,
  unwatch: unwatch,
};

if (api.module.__esModule) api.module = api.module.default;

if (module.hot) {
  module.hot.accept([moduleId], function() {
    api.module = require("./default.vert");
    if (api.module.__esModule) api.module = api.module.default;
    for (i = 0, l = watchers.length; i < watchers.length; i++) watchers[i](api.module)
  });
}

function watch (cb) {
  watchers.push(cb)
}

function unwatch (cb) {
  var aliveWatchers = [];
  for (i = 0, l = watchers.length; i < watchers.length; i++) {
    if (watchers[i] !== cb) aliveWatchers.push(watchers[i]);
  }
  watchers = aliveWatchers;
}

module.exports = api;
