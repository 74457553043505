import Inrtia from 'inrtia'

export default class Cursor {
  constructor (el) {
    this.el = el
    this.hover = false
    this.first = false
    this.inrtia = new Inrtia({
      value: [0, 0],
      precision: 0.1,
      perfectStop: true,
      friction: 4
    })

    this.toggleEvents()
    this.render()
  }

  toggleEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    document.documentElement[method]('mousemove', this.mousemove)
    document.documentElement[method]('mousedown', this.mousedown)
    document.documentElement[method]('mouseup', this.mouseup)
  }

  mousedown = () => {
    this.el.classList.add('mousedown')

  }

  mouseup = () => {
    this.el.classList.remove('mousedown')
  }

  mousemove = ({ clientX, clientY, target }) => {
    const tagName = target.tagName.toLowerCase()
    const hover = (tagName === 'a' || tagName === 'button') && !target.classList.contains('home__link')

    if (hover !== this.hover) {
      this.hover = hover
      this.el.classList.toggle('hover', hover)
    }

    if (this.first) {
      this.inrtia.targetValue = this.inrtia.value = ([clientX, clientY])
      this.inrtia.stopped = false
      this.first = false
    } else {
      this.inrtia.to([clientX, clientY])
    }
  }

  render = () => {
    const [x, y] = this.inrtia.update()
    if (!this.inrtia.stopped)
      this.el.style.transform = `translate3d(${x}px, ${y}px, 0)`

    window.requestAnimationFrame(this.render)
  }

  flush () {
    this.toggleEvents(false)
  }
}
