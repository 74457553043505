import raf from '@internet/raf'

import text from 'helpers/text'

export default class Player {
  constructor (el, refs) {
    this.refs = refs
    this.el = el
  }

  init (videoPlayer) {
    this.videoPlayer = videoPlayer
    this.toggleEvents()
    this.statusUpdate()
  }

  toggleEvents (add = true) {
    if (!this.videoPlayer) return
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.refs.play[method]('click', this.play)
    this.refs.pause[method]('click', this.pause)
    this.refs.bar[method]('mousedown', this.mousedown)
    document.body[method]('mouseup', this.mouseup)
    document.body[method]('mousemove', this.mousemove)
    this.videoPlayer[method]('play', this.statusUpdate)
    this.videoPlayer[method]('pause', this.statusUpdate)
    this.videoPlayer[method]('ended', this.statusUpdate)
    raf[add ? 'add' : 'remove'](this.timeUpdate)
  }

  mousedown = ({ clientX }) => {
    this.drag = true
    this.seek(clientX)
  }

  mouseup = () => {
    this.drag = false
  }

  mousemove = ({ clientX }) => {
    if (!this.drag) return
    this.seek(clientX)
  }

  seek (clientX) {
    const { duration } = this.videoPlayer
    const progress = (clientX - this.bounds.left) / this.bounds.width
    this.videoPlayer.currentTime = Math.min(duration, Math.max(0, progress * duration))
  }

  statusUpdate = (event) => {
    this.el.classList.toggle('playing', !this.videoPlayer.paused)
  }

  timeUpdate = (event) => {
    const { currentTime, duration } = this.videoPlayer
    const progress = (currentTime / duration)
    this.refs.timeline.style.transform = `scaleX(${progress})`
    this.refs.cursor.style.transform = `translateX(${(1 - progress) * -100}%)`

    const h = Math.floor(currentTime / 3600)
    const m = Math.floor(currentTime % 3600 / 60)
    const s = Math.floor(currentTime % 3600 % 60)
    const ms = Math.floor((currentTime - Math.floor(currentTime)) * 24)

    this.refs.timecode.innerHTML = `TC ${text.spanify(text.pad(h))}:${text.spanify(text.pad(m))}:${text.spanify(text.pad(s))}:${text.spanify(text.pad(ms))}`
  }

  play = () => {
    this.videoPlayer.play()
  }

  pause = () => {
    this.videoPlayer.pause()
  }

  click = (event) => {
    if (this.player.paused) this.player.play()
    else this.player.pause()
  }

  resize () {
    this.bounds = this.refs.bar.getBoundingClientRect()
  }

  flush () {
    this.toggleEvents(false)
  }
}
