
import detect from 'helpers/detect'
import SuperPage from 'navigation/SuperPage'

export default class AbstractProject extends SuperPage { 
 static pageName = 'AbstractProject'; 
  constructor (el, options = {}) {
    super(...arguments)
    this.init()
  }

  toggleEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    // if (detect.touch) {
      this.refs.info[method]('click', this.toggleInfo)
    // } else {
    //   this.refs.info[method](detect.touch ? 'touchend' : 'mouseleave', this.closeInfo)
    //   this.refs.info[method](detect.touch ? 'touchstart' : 'mouseenter', this.openInfo)
    // }
  }

  toggleInfo = ({currentTarget} = {}) => {
    const t = currentTarget.parentNode
    currentTarget.parentNode.classList.toggle('open-info')
  }

  openInfo = event => {
    event.currentTarget.parentNode.classList.add('open-info')
  }

  closeInfo = event => {
    event.currentTarget.parentNode.classList.remove('open-info')
  }

  init () {
    this.toggleEvents()
  }

  getVideoUrl () {
    return false
  }

  flush () {
    this.toggleEvents(false)
    super.flush()
  }

  resize () {
    super.resize()
  }
}
